@use '@ingka/base/_mixins.scss' as *;
@import '../../../constants.scss';

.wrapper {
    padding-inline: $space-200;
}

.heading {
    margin-block-end: $space-300;
    font-size: 1.375rem;
}

.listView {
    margin-block-end: $space-125
}

.logoutButton {
    padding-block-start: $space-200;
    padding-block-end: $space-125;
    padding-inline: $space-125;
    text-decoration: underline;
}

.listViewItem:first-child {
    border-top: 1px solid $colour-elevation-1;
}