@import '@ingka/svg-icon/_mixins.scss';
@import '@ingka/switch/_mixins.scss';
@import '@ingka/forms/_mixins.scss';
@import '@ingka/aspect-ratio-box/_mixins.scss';
@import '@ingka/image/_mixins.scss';
@import '@ingka/action-list/_mixins.scss';
@import '@ingka/focus/_mixins.scss';

@import '../../../constants.scss';

@include svg-icon($prefix);
@include checkbox($prefix);
@include checkbox-focus($prefix);
@include switch($prefix);
@include switch-focus($prefix);
@include radio-focus($prefix);
@include radio($prefix);
@include aspect-ratio-box($prefix);
@include image($prefix);
@include action-list($prefix);
@include action-list-focus($prefix);

// Bug in skapa when using images
.list-item {
  .skapa-mvagent-wrapper-prefix-action-list-item__action--with-image {
    position: relative;
  }
}
