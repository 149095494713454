@use '@ingka/variables/style.scss' as *;

.continueAsGuestTitle {
  margin-block-end: $space-100;
}

.continueAsGuestBody {
  margin-block-end: $space-100;
}

.orDivider {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  column-gap: $space-100;
  color: $colour-text-and-icon-4;
  font-size: $font-size-75;
  margin-block: $space-150;

  .line {
    background: $colour-neutral-4;
    height: 1px;
  }
}

.checkingForMerge {
  opacity: 0.5;
}

.loginPageHeader {
  margin-block-end: $space-150;
}

.loginPageDescription {
  margin-block-end: $space-50;
}

.inlineMessage {
  margin-block: $space-150;
}

.loginEmail {
  margin-block-end: $space-150;
}

.loginPassword {
  margin-block-end: $space-100;
}

.resetPasswordLink {
  display: inline-block;
  width: auto;
}

.loginButton {
  margin-block-start: $space-300;
  margin-block-end: $space-100;
}

.divider {
  display: flex;
  align-items: center;
  width: 100%;
  gap: $space-100;

  margin-block: $space-50 $space-150;
  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: $thickness-thin;
    background-color: $colour-neutral-4;
  }
}