@import '@ingka/svg-icon/_mixins.scss';
@import '@ingka/focus/_mixins.scss';
@import '@ingka/button/_mixins.scss';

@import '../../../constants.scss';

@include svg-icon($prefix);
@include btn($prefix);
@include btn-focus($prefix);

.#{$prefix}noOutline {
  outline: none;
}
