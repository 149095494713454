@use '@ingka/variables/style.scss' as *;

.header {
  margin-block-end: $space-150;
  font-size: $font-size-500;
}

.button {
  margin-block: $space-150;
}

.divider {
  display: flex;
  align-items: center;
  width: 100%;
  gap: $space-100;

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: $colour-neutral-4;
  }
}

#menuSheetsModal {
  overflow: hidden;
}
