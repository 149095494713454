@use '@ingka/variables/style.scss' as *;

#add-or-remove-modal {
  .modalFooter {
    display: block;

    button:not(:last-child) {
      margin-block-end: $space-75;
    }
  }
}
