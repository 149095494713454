@import '@ingka/variables/style.scss';

.create-list-body {
  margin: 0 0 $space-150 0;
  white-space: pre-line;
}

.inlineErrorMessage {
  margin: 0 0 $space-100 0;
}
